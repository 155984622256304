<template>
    <div class="view pa24">
        <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="产品打印机" name="first">
                <productList v-if="activeName == 'first'"/>
            </el-tab-pane>
            <el-tab-pane label="箱标打印机" name="second">
                <boxList v-if="activeName == 'second'"/>
            </el-tab-pane>
            <el-tab-pane label="扫码枪" name="third">
              <scanGunList v-if="activeName == 'third'"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import productList from "./components/productList";
    import boxList from "./components/boxList";
    import scanGunList from "./components/scanGunList";
    export default {
        name: "printIndex",
        data() {
            return {
                activeName: "first",
            };
        },
        components: {
            productList,
            boxList,
          scanGunList
        },
        filters: {

        },
        computed: {
            ...mapState(['routerArr']),
        },
        watch: {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {

        },
        methods: {
            handleClick(tab) {
                const { name } = tab;
                this.activeName = name;
            },
        },

    };
</script>

<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 180px;
        text-align: center;
    }

    /deep/ .avatar-coverImg .el-upload--text {
        width: 100px;
        height: 100px;
    }

    /deep/ .avatar-coverImg .avatar-uploader-icon {
        line-height: 100px;
    }

    .select-goods-list {
        margin-bottom: 20px;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 0 20px;
        background: #ffffff;
        transition: all 0.4s;
    }

    .select-goods-list:hover {
        background: #f5f5f6;
        transition: all 0.4s;
        cursor: pointer;
    }

    /deep/ .disabled .el-upload--picture-card {
        display: none;
    }
</style>


