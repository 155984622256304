<template>
    <div class="view pa24">
        <el-button
                class="mr10"
                type="primary"
                @click="addTask"
        >添加设备
        </el-button
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="currentPage"
                :total="total"
        >
            <template v-slot:table>
                <el-table-column align="center" type="index" label="序号"/>
                <el-table-column
                        prop="deviceName"
                        align="center"
                        label="设备名称"
                />
                <el-table-column prop="deviceNumber" align="center" label="设备编号"/>
                <el-table-column align="center" prop="state" label="设备类型">
                    <template slot-scope="scope">
                        {{ scope.row.type|deviceStatusFilter }}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" align="center" label="创建时间"/>

                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              ">编辑
                        </el-button>
                        <customPopconfirm
                                confirm-button-text="确定"
                                k cancel-button-text="取消"
                                @confirm="del(scope.row.deviceId)"
                                icon="el-icon-info"
                                icon-color="red"
                                title="确定要删除吗？">
                            <el-button
                                    slot="reference"
                                    class="ml10"
                                    style="color: #fd634e"
                                    type="text"
                            >删除
                            </el-button
                            >
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog
                :title="diagTitle"
                :visible.sync="centerDialogVisible"
                @open="openDialog"
                @close="close"
                :modal-append-to-body="false"
                :destroy-on-close="true"
                :close-on-click-modal="false"
                width="50%"
                center
        >
            <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="120px"
            >
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input v-model="ruleForm.deviceName"></el-input>
                </el-form-item>
                <el-form-item label="设备编号" prop="deviceNumber">
                    <el-input v-model="ruleForm.deviceNumber"></el-input>
                </el-form-item>
                <el-form-item label="设备类型" prop="type">
                    <el-select v-model="ruleForm.type" placeholder="请选择">
                        <el-option
                                v-for="item in proOption"
                                :key="item.id"
                                :label="item.title"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item  label="绑定箱标设备" prop="boxDeviceId">
                    <el-select v-model="ruleForm.boxDeviceId" placeholder="请选择">
                        <el-option
                                v-for="item in xbOption"
                                :key="item.deviceId"
                                :label="item.deviceName"
                                :value="item.deviceId">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine"
        >确 定</el-button
        >
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import {mapState} from 'vuex';
    import customPopconfirm from "@/components/common/customPopconfirm";
    import {
        queryPage,
        add,
        deleteById,
        update,
        getTaskNums,
    } from "@/api/deviceManage";
    import {getDataTimeSec} from "@/utils";
    import { deviceStatus } from '@/utils/constant'

    export default {
        name: "productList",
        data() {
            return {
                diagTitle: '添加设备',
                proValue: '',
                proOption: [
                    {
                        id: 1,
                        title: '产品打印机',
                        value: 1
                    },
                    {
                        id: 2,
                        title: '箱标打印机',
                        value: 2
                    },
                  {
                    id: 3,
                    title: '扫码枪',
                    value: 3
                  }
                ],
                xbOption: [],
                //
                tableData: [],
                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                loading: false, //表格加载
                total: 0, //总条数
                taskList: [],
                centerDialogVisible: false,
                bEdit: false,
                editUpdate: true,
                isUpdate: true,
                fileList: [],
                userInfo: this.$store.state.loginRoot.userInfo,
                ruleForm: {
                    deviceName: '', //设备名称
                    deviceNumber: '', // 设备编号
                    boxDeviceId: '', // 箱标设备
                    type: 1 // 设备类型
                },
                rules: {
                    deviceName: [{required: true, message: "请输入设备名称", trigger: "blur"}],
                    deviceNumber: [{required: true, message: "请输入设备编号", trigger: "blur"}]
                },
            };
        },
        components: {
            commonTable,
            customPopconfirm
        },
        filters: {
            deviceStatusFilter(type) {
                return deviceStatus[type]
            }
        },
        computed: {
            ...mapState(['routerArr']),
        },
        watch: {},
        created() {

        },
        mounted() {
            this.queryPage();
        },
        beforeDestroy() {

        },
        methods: {
            async queryPage() {

                let data = {
                    pageSize: this.pageSize,
                    pageNum: this.currentPage,
                    type: 1
                };

                try {
                    this.loading = true;
                    const result = await queryPage(data);
                    this.loading = false;
                    const { total, list } = result.data;
                    this.tableData = list;
                    this.total = total;
                } catch (error) {
                    this.loading = false;
                }
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.ruleForm.coverImg = "";
            },

            /**@method 模态框打开时的回调 */
            openDialog() {

            },
            /**@method 模态框关闭的回调 */
            close() {
                (this.ruleForm = {
                    deviceName: '', //任务名称
                    deviceNumber: '', // 产品批次号
                    type: 0 // 产品数
                })
                this.bEdit = false;
                this.diagTitle = '添加设备'
            },
            addTask() {
                this.centerDialogVisible=true;
                this.editUpdate = false
                this.diagTitle = '添加设备'
                this.getBoxBatch();
            },
            /**@method 编辑
             * @param {Object} val - 当前点击行的值
             */
            edit(val, b) {
                this.editUpdate = true
                this.diagTitle = '编辑设备'
                this.getBoxBatch();
                let data = JSON.parse(JSON.stringify(val));
                this.centerDialogVisible = true;
                this.ruleForm = data;
                console.log(this.ruleForm);
                this.bEdit = b;
            },
            /**@method 添加任务 */
            determine() {
                this.$refs.ruleForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            let data = {
                                ...this.ruleForm
                            };
                            this.$message({
                                message: "正在添加",
                            });
                            if (this.editUpdate) {
                                await update(data);
                            } else {
                                await add(data);
                            }
                            this.centerDialogVisible = false;
                            this.$message.closeAll();
                            if (this.editUpdate) {
                                this.$message({
                                    message: "编辑成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: "添加成功",
                                    type: "success",
                                });
                            }
                            await this.queryPage();
                        } catch (error) {
                            this.$message({
                                message: error.message,
                                type: "error",
                            });
                        }
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            /**@method 删除
             * @param {String} val - 文章id
             */
            async del(val) {
                try {
                    await deleteById({deviceId: val});
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    await this.queryPage();
                } catch (error) {
                    console.log(error);
                }
            },
            async getBoxBatch() {
                let data = {
                    pageSize: 20,
                    pageNum: 1,
                    type: 2,
                    status: 0
                };
                if (this.editUpdate) {
                    delete data.status
                } else {
                    data.status = 0
                }
                const result = await queryPage(data)
                const { total, list } = result.data;
                this.xbOption = list
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.queryPage();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.queryPage();
            },
        },

    };
</script>

<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 180px;
        text-align: center;
    }

    /deep/ .avatar-coverImg .el-upload--text {
        width: 100px;
        height: 100px;
    }

    /deep/ .avatar-coverImg .avatar-uploader-icon {
        line-height: 100px;
    }

    .select-goods-list {
        margin-bottom: 20px;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 0 20px;
        background: #ffffff;
        transition: all 0.4s;
    }

    .select-goods-list:hover {
        background: #f5f5f6;
        transition: all 0.4s;
        cursor: pointer;
    }

    /deep/ .disabled .el-upload--picture-card {
        display: none;
    }
</style>



